import React from "react";

import Layout from "../components/organisms/Layout/Layout";
import SEO from "../components/atoms/SEO/SEO";
import Section from "../components/organisms/Section/Section";
import PageWidth from "../components/organisms/PageWidth/PageWidth";
import CartPage from "../components/organisms/Shop/CartPage/CartPage";
import { htmlEncode } from "../utils/html";

const PaymentSuccessful = () => (
  <>
    <CartPage isBought={true} />
    <Layout>
      <SEO
        title="Vielen Dank für Deine Bestellung"
        description="Deine Bestellung ist bei mir eingegangen."
      />
      <Section>
        <PageWidth>
          <h1>Danke für Deine Bestellung</h1>
          <p className="text-max-line">
            In den nächsten Minuten wirst Du von mir eine Bestätitung per E-Mail
            erhalten. Sobald ich den Honig versende melde ich mich wieder per
            E-Mail bei Dir.
          </p>
          <p className="text-max-line">
            Falls Du Fragen zu Deiner Bestellung hast, kannst Du Dich jederzeit
            bei mir melden:{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: htmlEncode("ludwig@stadthonig.shop")
              }}
            />
          </p>
          <p className="text-max-line">
            Bis sehr bald
            <br />
            Ludwig
          </p>
        </PageWidth>
      </Section>
    </Layout>
  </>
);

export default PaymentSuccessful;
